import React, { useState, useCallback, useEffect } from 'react';
import { Button, TextField, Box, CircularProgress, Fade } from '@mui/material';
import Dropdown from './Dropdown';
import useAuth from '../../../hooks/useAuth';
import NonSgb from './NonSgb';
import Sgb from './Sgb';
import { urlOptions, fetchBondsData } from '../../../api/bondsAPI';

function BondsApp({ setIsDataLoading, persistentData, setPersistentData }) {
  const [data, setData] = useState(persistentData.data || []);
  const [url, setUrl] = useState(persistentData.url || urlOptions['NSE G-Sec']);
  const [selectedValue, setSelectedValue] = useState(persistentData.selectedValue || 'NSE G-Sec');
  const { userInfo, accessToken } = useAuth();
  const [searchTerm, setSearchTerm] = useState(persistentData.searchTerm || '');
  const [isSgb, setIsSgb] = useState(persistentData.isSgb || false);
  const [loading, setLoading] = useState(false);

  // Update persistentData when state changes
  useEffect(() => {
    setPersistentData({
      data,
      url,
      selectedValue,
      searchTerm,
      isSgb,
    });
  }, [data, url, selectedValue, searchTerm, isSgb, setPersistentData]);

  const fetchData = useCallback(async () => {
    if (!!url && url !== '...' && userInfo && accessToken) {
      try {
        setLoading(true);
        setIsDataLoading(true);
        const responseData = await fetchBondsData(url, accessToken);
        setData(responseData);
        setIsSgb(url.includes('sgb') || responseData.some(item => item.hasOwnProperty('bond_symbol')));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setIsDataLoading(false);
      }
    }
  }, [url, userInfo, setIsDataLoading, accessToken]);

  const handleRefresh = () => {
    fetchData();
  };

  const handleDropdownChange = (newValue) => {
    setSelectedValue(newValue);
    setUrl(urlOptions[newValue]);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter((item) => {
    return Object.values(item).some(
      (val) => val && val.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  return (
    <Box position="relative">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <Dropdown
          selectedValue={selectedValue}
          onChange={handleDropdownChange}
          options={Object.keys(urlOptions)}
        />
        <Button variant="contained" color="primary" onClick={handleRefresh} sx={{ m: '0 1rem' }} size='small'>
          Refresh
        </Button>
        <TextField
          id="search"
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          size="small"
          sx={{ m: '0 1rem' }}
        />
      </div>
      {loading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor="rgba(255, 255, 255, 0.8)"
          zIndex={1}
        >
          <CircularProgress size={60} thickness={4} />
        </Box>
      )}
      <Fade in={!loading} timeout={300}>
        <Box>
          {isSgb ? (
            <Sgb data={filteredData} />
          ) : (
            <NonSgb data={filteredData} />
          )}
        </Box>
      </Fade>
    </Box>
  );
}

export default BondsApp;
