import axios from 'axios';
import {
    getTradeInstancesUrl,
    openTradeInstancesUrl,
    getStrategiesUrl,
    BASE_URL,
    generateTradeInstanceCommentsUrl,
    generateTradeInstanceNetPnLUrl,
    generateTradeInstanceExpiriesUrl,
    generateTradeInstanceCapitalUrl,
    generateUpdateTradeInstanceExitTypeUrl,
    generateUpdateTradeInstanceIdUrl,
    generateTradeInstanceSwitchToManualUrl,
    generateTradeInstanceSwitchToAutoUrl,
    disretionaryTradeInstanceUrl,
    BASE_URL_DISRECTIONARY,
} from '../Constants';
import { handleApiError } from './helpers';

const fetchTradeInstances = async (pageNum) => {
    try {
        const response = await axios.get(`${getTradeInstancesUrl}page=${pageNum}&limit=25`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const fetchOpenTradeInstances = async (pageNum = 1, limit = 1000) => {
    try {
        const response = await axios.get(`${openTradeInstancesUrl}page=${pageNum}&limit=${limit}`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const createTradeInstance = async (tradeInstance) => {
    try {
        const response = await axios.post(disretionaryTradeInstanceUrl, tradeInstance);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const fetchStrategies = async () => {
    try {
        const response = await axios.get(getStrategiesUrl);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const fetchTradeLegs = async (instanceId) => {
    try {
        const response = await axios.get(`${BASE_URL}/trade-legs/${instanceId}?skip=0&limit=100`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const getTradeInstanceById = async (tradeInstanceId) => {
    try {
        const response = await axios.get(`${BASE_URL}/trade-instance/${tradeInstanceId}`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const deleteTradeInstanceById = async (tradeInstanceId) => {
    try {
        const response = await axios.delete(`${BASE_URL_DISRECTIONARY}/trade-instance/${tradeInstanceId}/`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const getTradeLegById = async (tradeLegId) => {
    try {
        const response = await axios.get(`${BASE_URL}/trade-leg/${tradeLegId}`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const getComments = async (tradeInstanceId) => {
    try {
        const response = await axios.get(generateTradeInstanceCommentsUrl(tradeInstanceId));
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const updateComments = async (tradeInstanceId, comments) => {
    try {
        const commentsArray = Array.isArray(comments) ? comments : [comments];
        const response = await axios.put(generateTradeInstanceCommentsUrl(tradeInstanceId), { comments: commentsArray });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const addComment = async (tradeInstanceId, comment) => {
    try {
        const response = await axios.post(generateTradeInstanceCommentsUrl(tradeInstanceId), { comment });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const deleteComments = async (tradeInstanceId) => {
    try {
        const response = await axios.delete(`${generateTradeInstanceCommentsUrl(tradeInstanceId)}`);
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const addNetPnL = async (tradeInstanceId, netPnL) => {
    try {
        const response = await axios.patch(generateTradeInstanceNetPnLUrl(tradeInstanceId), { net_pnl: netPnL });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const updateNetPnL = async (tradeInstanceId, netPnL) => {
    try {
        const response = await axios.put(generateTradeInstanceNetPnLUrl(tradeInstanceId), { net_pnl: netPnL });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const addExpiries = async (tradeInstanceId, expiries) => {
    try {
        const response = await axios.patch(generateTradeInstanceExpiriesUrl(tradeInstanceId), { expiries });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const updateExpiries = async (tradeInstanceId, expiries) => {
    try {
        const response = await axios.put(generateTradeInstanceExpiriesUrl(tradeInstanceId), { expiries });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const updateCapital = async (tradeInstanceId, capital) => {
    try {
        const response = await axios.put(generateTradeInstanceCapitalUrl(tradeInstanceId), { capital });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const updateExitType = async (tradeInstanceId, exitType) => {
    try {
        const response = await axios.put(generateUpdateTradeInstanceExitTypeUrl(tradeInstanceId), { exit_type: exitType });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const updateTradeInstanceId = async (tradeInstanceId, newTradeInstanceId) => {
    try {
        const response = await axios.put(generateUpdateTradeInstanceIdUrl(tradeInstanceId), { new_trade_instance_id: newTradeInstanceId });
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const switchToManualMode = async (tradeInstanceId) => {
    try {
        const response = await axios.put(generateTradeInstanceSwitchToManualUrl(tradeInstanceId));
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

const switchToAutoMode = async (tradeInstanceId) => {
    try {
        const response = await axios.put(generateTradeInstanceSwitchToAutoUrl(tradeInstanceId));
        return response.data;
    } catch (error) {
        throw handleApiError(error);
    }
};

export {
    createTradeInstance,
    fetchTradeInstances,
    fetchOpenTradeInstances,
    fetchStrategies,
    fetchTradeLegs,
    getTradeInstanceById,
    deleteTradeInstanceById,
    getTradeLegById,
    getComments,
    updateComments,
    addComment,
    deleteComments,
    addNetPnL,
    updateNetPnL,
    addExpiries,
    updateExpiries,
    updateCapital,
    updateExitType,
    updateTradeInstanceId,
    switchToManualMode,
    switchToAutoMode
}
